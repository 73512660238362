<template>
    <v-card width="100%" class="fill-height container--fluid pa-0 ma-0" flat tile>
        <v-slide-x-transition appear>
            <v-img src="@/assets/images/fondo_login.jpg"
                   class="grey lighten-2 full-height"
                   position="center"
                   transition="slide-x-transition"
                   width="100%">
                <div class="fill-height bottom-gradient container--fluid" style="width: 100%">
                    <v-container fluid class="fill-height container--fluid" style="width: 100%; height: 100%">
                        <v-row justify="center" align="center">
                            <v-col cols="12" lg="7" xl="6" class="d-none d-sm-none d-md-flex">
                                <div class="text-center justify-center" style="width: 100%">
                                    <span class="text-h2 white--text font-weight-regular">
                                        Private Equity Baja
                                    </span>
                                    <h5 class="text-h5 white--text mt-4 op op-5 font-weight-regular">
                                        <p>
                                            Bienvenidos nuestros inversionistas
                                        </p>
                                    </h5>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="12" lg="5" xl="6">
                                <v-container fluid class="fill-height container--fluid">
                                        <div class="pa-7 pa-sm-12">
                                            <v-card rounded="lg" flat elevation="0" outlined light
                                                    class="text-center justify-center">
                                            <v-row justify="center" class="mx-1 mx-md-0">
                                                <v-col cols="12" lg="9" xl="6">
                                                    <div style="height: 50px"/>
                                                    <img width="100%" src="@/assets/images/logo-private-black-transparent.png"
                                                         alt="logo-private"/>
                                                    <h2 class="font-weight-bold mt-2 blue-grey--text text--darken-2">Iniciar Sesi&oacute;n</h2>
                                                    <h6 v-show="false" class="subtitle-1">
                                                        No tiene una cuenta a&uacute;n?
                                                        <a class href="/under-construction">Reg&iacute;strese</a>
                                                    </h6>
                                                    <v-form ref="form" v-model="valid"  lazy-validation @submit.prevent="submit">
                                                        <v-text-field
                                                            v-model="email"
                                                            :rules="emailRules"
                                                            class="mt-2"
                                                            label="E-mail"
                                                            outlined
                                                            required
                                                            @enter="submit">
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-model="password"
                                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                            :rules="passwordRules"
                                                            :type="showPassword ? 'text' : 'password'"
                                                            label="Contraseña"
                                                            outlined
                                                            required
                                                            @click:append="showPassword=!showPassword"
                                                            @enter="submit">
                                                        </v-text-field>
                                                        <v-otp-input
                                                            v-model="key"
                                                            :rules="keyRules"
                                                            length="6"
                                                            required>
                                                        </v-otp-input>
                                                        <v-btn
                                                            type="submit"
                                                            :disabled="!valid"
                                                            block
                                                            depressed
                                                            class="mr-4 white--text"
                                                            color="primary darken-1 caption">
                                                            Entrar
                                                        </v-btn>
                                                        <v-btn v-if="false"
                                                            block
                                                            depressed
                                                            class="mr-4 mt-1 white--text"
                                                            color="red darken-1 caption"
                                                            submit
                                                            target="_blank"
                                                            @click="loginWithGoogle">
                                                            <v-icon class="mr-1">mdi-google</v-icon>
                                                            Continuar con Google
                                                        </v-btn>
                                                    </v-form>
                                                    <div class="text-center mt-6">
                                                        <!--mdi-home-circle-->
                                                        <v-chip class="mr-2" pill @click="goHome">
                                                            <v-avatar left>
                                                                <v-btn class="white--text" color="secondary">
                                                                    <v-icon>mdi-home</v-icon>
                                                                </v-btn>
                                                            </v-avatar>
                                                            Ir al Inicio
                                                        </v-chip>
                                                        <v-chip class="mr-2" pill v-if="false" @click="loginWithGoogle">
                                                            <v-avatar left>
                                                                <v-btn class="white--text" color="red lighten-1">
                                                                    <v-icon>mdi-google</v-icon>
                                                                </v-btn>
                                                            </v-avatar>
                                                            Google
                                                        </v-chip>
                                                        <v-chip pill v-show="false">
                                                            <v-avatar left>
                                                                <v-btn class="white--text" color="primary lighten-1">
                                                                    <v-icon>mdi-facebook</v-icon>
                                                                </v-btn>
                                                            </v-avatar>
                                                            Facebook
                                                        </v-chip>
                                                        <div style="height:50px;"></div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-img>
        </v-slide-x-transition>
        <v-dialog
            v-model="dialogError"
            max-width="500"
            persistent>
            <v-card>
                <v-card-title class="text-h6 primary">
                    <span class="headline">Error de autentificaci&oacute;n</span>
                </v-card-title>

                <v-card-text>
                    <div class="text-h6 pa-10">{{ messageError }}</div>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                    <v-btn depressed class="primary darken-1 caption" @click.stop="closeDialogError">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="progressDialog"
            persistent
            width="300">
            <v-card
                color="primary"
                light>
                <v-card-text>
                    Validando las credenciales...
                    <v-progress-linear
                        class="mb-0"
                        color="white"
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import loginService from '@/providers/LoginService';

export default {
    name: "FormLoginComponent",
    data: () => ({
        valid: true,
        showPassword: false,
        progressDialog: false,
        dialogError: false,
        messageError: '',
        email: '',
        checkbox: false,
        key: "",
        keyRules: [
            v=> !!v || 'El código OTP es obligatorio'
        ],
        emailRules: [
            v => !!v || "El correo electrónico es obligatorio",
            v => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido"
        ],
        password: '',
        passwordRules: [
            v => !!v || "La contraseña es obligatoria.",
            v => (v || '').length >= 10 || 'La contraseña debe tener al menos de 10 carácteres.'
            // v => (v && v.length >= 10) || "La contraseña debe tener al menos de 10 caracteres"
        ]
    }),
    watch: {
        dialogError(val) {
            val || this.closeDialogError()
        }
    },
    methods: {
        goHome() {
            this.$store.dispatch('clearState');
            location.href = "https://portal.private.idooproject.com/";
        },
        loginWithGoogle() {
            loginService.loginWithGoogle().then(resp => {
                console.log('resp: ', resp);
            }).finally(() => {
               console.log('Done.');
            });
        },
        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.progressDialog = true;
                this.doLogin();
            }
        },
        doLogin() {
            console.log('lll')
            let passport = {
                email: this.email,
                password: this.password,
                key: this.key,
            };
            loginService.loginUser(passport).then(
                response => {
                    this.progressDialog = false;
                    this.dialogError = false;
                    if (response.valid) {
                        this.closeDialogError();
                        if (response.active) {
                            if (response.roles === "INVERSIONISTA") {
                                loginService.redirectToPage(response.token, 'investor-section');
                            } else {
                                loginService.redirectToPage(response.token, 'dashboard');
                            }
                        } else {
                            this.messageError = "El usuario que intenta autenticar se encuentra deshabilitado.";
                            this.dialogError = true;
                        }
                    } else {
                        this.dialogError = true;
                        this.messageError = response.messageText;
                    }
                }
            );
        },
        closeDialogError() {
            this.dialogError = false;
            this.messageError = '';
        }
    }
};
</script>

<style>
    .full-height {
        margin: 0;
        padding: 0;
        width: 100%;
        height: calc(100vh);
        overflow: hidden;
    }
</style>